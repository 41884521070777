import React from 'react';
import axios from "axios";
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Amplify } from "aws-amplify";

Amplify.configure(awsconfig);

// Url de la API.
const APIUrl = "https://93v294mryc.execute-api.us-east-1.amazonaws.com/prod/listVideos";
// Auth token.
const getAccessToken = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const accessToken = user.signInUserSession.idToken.jwtToken;
    // console.log('Access Token:', accessToken);
    return accessToken; 
  } catch (error) {
    console.log('Error:', error);
  }
};

function App() {

  // Función que se ejecuta al hacer click en el boton.
  const loadVideos = async () => {
        const token = await getAccessToken();
        //console.log('Access Token2:', token)
        axios.get(APIUrl, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {

          // Parseamos el json.
          const jsonData = response.data;
          const body = JSON.parse(jsonData.body);

          const imageContainer = document.getElementById('imageContainer');
          const videoContainer = document.getElementById('videoContainer');

          imageContainer.innerHTML = '';
          videoContainer.innerHTML = '';

          // Ponemos las imagenes en filas de 3 elementos.
          let row = document.createElement('div');
          row.className = 'row';

          body.forEach((video, index) => {
            if (index !== 0 && index % 3 === 0) {
              imageContainer.appendChild(row);
              row = document.createElement('div');
              row.className = 'row';
            }

            // Añadimos las imágenes.
            const imageElement = document.createElement('img');
            imageElement.src = video.imageUrl;
            imageElement.style.width = '300px';
            imageElement.style.height = '200px';
            imageElement.style.marginRight = '10px';

            // Al hacer click en la imagen, se llama a la función de reproduccon de video.
            imageElement.addEventListener('click', () => {
              showVideo(video.url);
            });

            imageContainer.appendChild(imageElement);
          });
        })
          .catch(error => {
            console.error('Error:', error);
          });
      }

// Funcion de reproduccion de video.
const showVideo = (url) => {
      const videoContainer = document.getElementById('videoContainer');
      videoContainer.innerHTML = '';

      const videoElement = document.createElement('video');
      videoElement.src = url;
      videoElement.controls = true;
      videoElement.className = 'video-player'
      videoElement.style.width = '800px';
      videoElement.style.height = '600px';
      videoElement.style.objectFit = 'fill';

      videoContainer.appendChild(videoElement);
    };

    return (
      <React.Fragment>
        <button id="btnLoadVideos" onClick={loadVideos}> Cargar Videos </button>
        <div id="imageContainer"></div>
        <div id="videoContainer"></div>
      </React.Fragment>
    );
  }

  export default App;
