// aws-exports.js
const awsmobile = {
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FQfSo0FGu",
    "aws_user_pools_web_client_id": "7ed109oje09tl5vhtlvhru461e",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ]
};

export default awsmobile;