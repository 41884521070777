import ReactDOM from 'react-dom/client';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from "aws-amplify";
import React from "react";
import awsExports from './aws-exports';
import Login from './Login';

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AmplifyProvider>
      <Authenticator.Provider>
        <Login />
      </Authenticator.Provider>
    </AmplifyProvider>
  </React.StrictMode>
);