import App from "./App";

import { Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

function Login() {

    return (
        <Authenticator>
            {({ signOut, user }) => (
                <main>
                    <h1>Hello {user.attributes.email}</h1>
                    <button onClick = {signOut}>Sign out</button>
                    <App />
                </main>
            )}
        </Authenticator>
    );
}

export default Login;
